html,
body {
  height: 100%;
  width: 100%;
  color: black;
}

.center-container {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
}

h1 {
    font-size: 200px;
    line-height: 0;
    font-family: 'Knewave', cursive;
    display: block;
}

h2 {
    font-size: 20px;
    font-family: 'Lato';
    display: block;
    letter-spacing: 5px;
    padding-top: 10px;
}


h3 {
    font-size: 45px;
    font-family: 'Knewave', cursive;
    display: block;
    letter-spacing: 5px;
}

p {
    font-family: 'Knewave', cursive;
    font-size: 25px;
}

ul, li {
    padding-top: 10px;
    font-family: 'Lato';
    font-weight: 900;
    font-size: 1em;
    letter-spacing: 3px;
    list-style: none;
    margin-left: 0;
    padding-left: 0;
}

span {
    font-family: 'Knewave', cursive;
    font-size: 25px;
}

.container {
    text-align: center;
}

.shaking {
    animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
    animation-iteration-count: infinite;
}

.color-blink {
    animation-duration: 400ms;
    animation-name: blink;
    animation-iteration-count: infinite;
    animation-direction: alternate;
}

@keyframes blink {
    from {
        color: #5eadec;
    }
    to {
        color:#ffeb00;
    }
}

.rotate-180 {
    transition: transform .7s ease-in-out;
}
.rotate-180:hover {
    transform: rotate(180deg);
}

/* 01 */

.em-computer {
    height: 10em !important;
    width: 10em !important;
}

.play-arrow {
    padding-top: 30px;
    cursor: pointer;
}


/* 02 */

.btn-container {
    text-align: center;
}

button {
    padding: 20px 40px;
    margin: 20px;
    background: white;
    border: 5px solid black;
    font-family: 'Knewave', cursive;
    font-size: 40px;
    cursor: pointer;
}

button.yes-btn {
    position: absolute;
}

button:hover {
    color: white;
    background: black;
    border: 5px solid black;  
}

button.no-btn:hover {
    opacity: 0;
}


/* 04 */



/* 05 */
.dark {
    background: black;
    color: white;
}

.count-down {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 400px;
    margin: 0px auto;
    border: 4px solid white;
}

.column {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
    min-height: 200px;
    justify-content: center;
}
  
.row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}

.one,
.four {
    background: white;
    color: black;
}


@keyframes shake {
    10%, 90% {
      transform: translate3d(-1px, 0, 0);
    }
    
    20%, 80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%, 50%, 70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%, 60% {
      transform: translate3d(4px, 0, 0);
    }
  }


